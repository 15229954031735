@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MADE Outer Sans Alt";
  src: url("fonts/MADE_Outer_Sans_Alt.otf") format("opentype");
}

* {
  --bs-navbar-toggler-border-color: white;
  --bs-navbar-toggler-border-radius: 2px;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
    background-color: white;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "system-ui";
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
}

.map-container {
  width:70vw;
  height: 50vh;
}

.map-container-program {
  width:100%;
  height: 475px;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #022855;
}

.dropdown-item.hover, .dropdown-item:hover {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #022855;
}

.dropdown-menu {
  background-color: #022855;
  border: none;
  text-align: center;
  border-radius: 0px;
}

.logo {
  width: 500px;
  height:500px;
  text-align: left;
  position: absolute;
  top: calc(50vh - (/* height */500px / 2));
  left: calc(50vw - (/* width */500px / 2));
}

.font-MADE {
  font-family: 'MADE Outer Sans Alt';
}

.slick-slide > div {
  margin: 10px;
  height: 100%;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slider-custom {
  position: fixed !important;
}

.slick-slider-custom .slick-list,
.slick-slider-custom .slick-list .slick-track
{
  height: 100%;
}

.slick-slider-custom .slick-slide > div {
  margin: 0px;
  height: 100%;
}

.navbar-dark, .navbar[data-bs-theme=dark] {
  --bs-navbar-color: #c1c1c1;
  --bs-navbar-hover-color: white;
  --bs-navbar-disabled-color: #c1c1c1;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: white;
  --bs-navbar-toggler-icon-bg: none;
  --bs-navbar-toggler-border-radius: 1rem;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}



